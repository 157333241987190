import { Divider, Toggle, Tooltip } from 'dodoc-design-system';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import {
  IntegrationiEnvisionLogo,
  PageNavigator,
  Toolbar,
  WarningLabel,
} from '_common/suite/components';
import { useDispatch, useIEnvision, useSelector } from '_common/hooks';
import { setCreating, setCursorMode, setSelectedCard, setShowNav } from '../PresentationSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useSuiteObject, useSuitePermissions } from '_common/suite';
import { usePresentationManager } from '../PresentationManager';
import Search from './Search';
import { Versions } from 'Editor/components';
import { openModal } from 'App/ModalContext/utils';
import { notify } from '_common/components/ToastSystem';
import ObjectApi from '_common/services/api/ObjectApi';
import { completeAction } from 'App/redux/onboardingSlice';
import { InteractionController } from '_common/components';
import { useFocusCard, useSlideId, useSlideMissingFonts } from 'Presentation/hooks';
import { ModalData } from 'App/ModalContext/ModalContextTypes';

const PresentationToolbar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isIEnvision = useIEnvision();
  const object = useSuiteObject();
  const manager = usePresentationManager();
  const currentSlideId = useSlideId();
  const { canSaveVersion, canRestoreVersion, canComment, canAccessAuditLog, canCreateTask } =
    useSuitePermissions();
  const { focusCard } = useFocusCard();

  const showNav = useSelector((state) => state.presentation.general.showNav);
  const currentPageNumber = useSelector((state) => state.presentation.general.currentSlide);
  const cursorMode = useSelector((state) => state.presentation.general.cursorMode);
  const loadedVersion = useSelector((state) => state.presentation.general.loadedVersion);
  const versionHistory = useSelector((state) => state.presentation.general.versionHistory);
  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);
  const unsupportedElements = useSelector(
    (state) => state.presentation.general.unsupportedElements,
  );
  const slideMissingFonts = useSlideMissingFonts();

  const inCurrentVersion = loadedVersion == null;

  const totalPageNumber = manager.navigation?.numSlides;

  const openAuditLogModal = () => {
    const navigateToObject: ModalData['DocumentAuditLogModal']['navigateToObject'] = ({
      objectId,
      objectType,
    }) => {
      if (objectType === 'task' || objectType === 'comment') {
        focusCard({ objectId, objectType });
      }
    };

    openModal({
      modal: 'DocumentAuditLogModal',
      data: { objectId: object.id, objectType: object.type, navigateToObject },
    });
  };

  const openSaveVersionModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'SaveVersionModal',
        data: {
          type: 'saveVersion',
        },
      }),
    );
  };

  const openRestoreVersionModal = () => {
    if (inCurrentVersion) {
      return;
    }

    openModal({
      modal: 'ConfirmationModal',
      data: {
        onConfirm: async () => {
          try {
            await manager.restoreVersion(loadedVersion);

            notify({
              type: 'success',
              title: 'DOCUMENT_VERSION_RESTORED',
              message: 'THE_DOCUMENT_VERSION_WAS_SUCCESSFULLY_RESTORED',
            });
            dispatch(ObjectApi.util.invalidateTags([{ type: object.type, id: object.id }]));
          } catch (e) {
            throw e;
          }
        },
        title: 'RESTORE_DOCUMENT_VERSION',
        //Message key includes 'PDF' but the message itself doesn't
        message: 'ARE_YOU_SURE_YOU_WANT_TO_RESTORE_THIS_PDF_DOCUMENT_VERSION',
        messageValues: { versionNumber: loadedVersion },
        confirmButtonTextId: 'RESTORE_VERSION',
        confirmButtonType: 'primary',
        modalWidth: '60rem',
        cancelButtonTextId: 'global.cancel',
      },
    });
  };

  const handleCursorMode = (mode: 'normal' | 'pan') => {
    dispatch(setCursorMode(mode));
  };

  const handleNewCard = (type: 'task' | 'comment') => {
    manager.restoreSelection();

    if (selectedShape) {
      //If returned anchor is null, means there is no range, so generating a shape anchor
      const anchor = manager.getPresentationAnchor() ?? [
        { id: currentSlideId },
        { id: selectedShape },
      ];

      dispatch(
        setCreating({
          slide: false,
          type,
          anchor,
        }),
      );
    } else {
      dispatch(setSelectedCard(null));
      dispatch(
        setCreating({
          slide: true,
          type,
        }),
      );
    }
  };

  const handleNewTask = () => {
    dispatch(completeAction('presentation_tasks_openTask'));
    handleNewCard('task');
  };
  const handleNewComment = () => {
    dispatch(completeAction('presentation_comments_openComment'));
    handleNewCard('comment');
  };

  const handleLoad = (index: number | null) => {
    manager.loadVersion(index);
  };

  const handleCheckUnsupportedElements = () => {
    openModal({
      modal: 'WarningModal',
      data: {
        title: 'UNSUPPORTED_ELEMENTS',
        description: 'SLIDE_CONTAINS_UNSUPPORTED_ELEMENTS_WONT_RENDER_CORRECTLY',
        groupTitle: 'UNSUPPORTED_ELEMENTS',
        groupItems: unsupportedElements,
      },
    });
  };

  const handleCheckUnsupportedFonts = () => {
    openModal({
      modal: 'WarningModal',
      data: {
        title: 'MISSING_FONTS',
        description: 'THIS_SLIDE_HAS_EXTERNAL_FONTS_THAT_WILL_NOT_BE_RENDERED',
        groupTitle: 'EXTERNAL_FONTS',
        groupItems: slideMissingFonts.map((font) => font.label),
      },
    });
  };

  return (
    <>
      <Toolbar.Left>
        <Tooltip
          content={intl.formatMessage({ id: 'SIDEBAR' })}
          placement="top"
          testId="thumbnails-tooltip"
        >
          <Toggle
            variant="ghost"
            isToggled={showNav}
            onClick={() => dispatch(setShowNav(!showNav))}
            size="medium"
            icon={showNav ? 'SidepanelBlue' : 'SidepanelGrey'}
            testId="thumbnails-toggle"
          />
        </Tooltip>
        <Divider vertical margin="0 1rem" />
        {versionHistory && (
          <>
            <Versions
              loadedVersion={loadedVersion}
              versions={object.lifecycle.versions}
              loadVersion={handleLoad}
              width="20"
              testId="versions"
              supportOriginalVersion
            />
            <Divider vertical margin="0 1rem" />
            <Tooltip
              content={
                inCurrentVersion
                  ? intl.formatMessage({ id: 'CANNOT_RESTORE_VERSION_WHILE_IN_CURRENT_VERSION' })
                  : intl.formatMessage({ id: 'RESTORE_VERSION' })
              }
              placement="top"
              testId="restore-version-tooltip"
            >
              <Toggle
                disabled={inCurrentVersion || !canRestoreVersion}
                margin="0 0.5rem 0 0"
                variant="ghost"
                size="medium"
                onClick={openRestoreVersionModal}
                icon="PDFRestoreGrey"
                testId="restore-version-toggle"
              />
            </Tooltip>
            <Divider vertical margin="0 1rem" />
          </>
        )}
        {!isIEnvision && !versionHistory && (
          <>
            {canAccessAuditLog && (
              <Tooltip
                content={intl.formatMessage({ id: 'DOCUMENT_AUDIT_LOG' })}
                placement="top"
                testId="audit-log-tooltip"
              >
                <Toggle
                  margin="0 1rem 0 0"
                  variant="ghost"
                  size="medium"
                  onClick={openAuditLogModal}
                  icon="PDFAuditLog"
                  testId="audit-log-toggle"
                />
              </Tooltip>
            )}
            {canSaveVersion && (
              <>
                <Tooltip
                  content={intl.formatMessage({ id: 'SAVE_VERSION' })}
                  placement="top"
                  testId="save-version-tooltip"
                >
                  <Toggle
                    variant="ghost"
                    size="medium"
                    onClick={openSaveVersionModal}
                    icon="SaveGrey"
                    testId="save-version-toggle"
                  />
                </Tooltip>
                <Divider vertical margin="0 1rem" />
              </>
            )}
          </>
        )}
        {manager.navigation && (
          <PageNavigator
            currentPageNumber={currentPageNumber}
            totalPageNumber={totalPageNumber}
            goToPage={manager.navigation.goTo}
            onPageDown={manager.navigation.next}
            onPageUp={manager.navigation.previous}
          />
        )}
      </Toolbar.Left>
      <Toolbar.MiddleLeft>
        <Tooltip
          placement="top"
          content={intl.formatMessage({ id: 'MOUSE' })}
          testId="menu-mouse-tooltip"
        >
          <Toggle
            variant="ghost"
            isToggled={cursorMode === 'normal'}
            onClick={() => handleCursorMode('normal')}
            size="medium"
            icon={cursorMode === 'normal' ? 'CursorBlue' : 'CursorGrey'}
            testId="menu-mouse-toggle"
          />
        </Tooltip>
        <Tooltip
          placement="top"
          content={intl.formatMessage({ id: 'PAN' })}
          testId="menu-pan-tooltip"
        >
          <Toggle
            margin="0 0 0 1rem"
            variant="ghost"
            isToggled={cursorMode === 'pan'}
            onClick={() => handleCursorMode('pan')}
            size="medium"
            icon={cursorMode === 'pan' ? 'HandBlue' : 'HandGrey'}
            testId="menu-pan-toggle"
          />
        </Tooltip>
      </Toolbar.MiddleLeft>
      <Toolbar.Middle>
        <Divider vertical margin="0 1rem" />
      </Toolbar.Middle>
      <Toolbar.MiddleRight>
        <InteractionController
          environment="presentation"
          rules={[
            {
              interaction: 'presentation_toolbar_comment',
              actions: ['presentation_comments_createComment'],
            },
          ]}
        >
          <Tooltip
            content={
              canComment
                ? intl.formatMessage({ id: 'NEW_COMMENT' })
                : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
            }
            placement="top"
            testId="menu-comment-tooltip"
          >
            <span
              id="presentation-toolbar-comment"
              style={{ position: 'relative', margin: '0 0 0 1rem', zIndex: 1 }}
            >
              <Toggle
                variant="ghost"
                onClick={handleNewComment}
                size="medium"
                icon={'PDFCommentGrey'}
                disabled={!canComment}
                testId="menu-comment-toggle"
              />
            </span>
          </Tooltip>
        </InteractionController>
        <Tooltip
          content={
            canCreateTask
              ? intl.formatMessage({ id: 'NEW_TASK' })
              : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
          }
          placement="top"
          testId="menu-task-tooltip"
        >
          <span
            id="presentation-toolbar-task"
            style={{ position: 'relative', margin: '0 0 0 1rem', zIndex: 1 }}
          >
            <Toggle
              variant="ghost"
              onClick={handleNewTask}
              size="medium"
              icon={'TaskGrey'}
              disabled={!canCreateTask}
              testId="menu-task-toggle"
            />
          </span>
        </Tooltip>
      </Toolbar.MiddleRight>
      <Toolbar.Right>
        {unsupportedElements.length ? (
          <WarningLabel
            label="UNSUPPORTED_ELEMENTS"
            margin="0 2rem 0 0"
            onClick={handleCheckUnsupportedElements}
          />
        ) : null}
        {slideMissingFonts.length ? (
          <WarningLabel
            label="MISSING_FONTS"
            margin="0 2rem 0 0"
            onClick={handleCheckUnsupportedFonts}
          />
        ) : null}
        <IntegrationiEnvisionLogo />
        <Search />
      </Toolbar.Right>
    </>
  );
};

export default memo(PresentationToolbar);
